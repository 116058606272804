<template>
  <section>
    <Banner />
    <Modal />
    <Help />
    <Roots />
    <Mission />
    <Team />
  </section>
</template>

<script>
import Banner from "./Banner";
import Modal from "./Modal";
import Help from "./Help";
import Roots from "./Roots";
import Mission from "./Mission";
import Team from "./Team";
export default {
  components: {
    Banner,
    Modal,
    Help,
    Roots,
    Mission,
    Team
  },
  metaInfo: {
    title: "Who We Are | The Giving Branch"
  }
};
</script>

<style scoped>
</style>
<template>
  <section class="featured">
    <div class="container">
      <div class="content">
        <h2 class="text__pistachio">Brooklyn's Story</h2>
        <p
          class="text__md text__powder"
        >Brooklyn faced abandonment at a young age due to her complex medical situation. Through her adopted family, she's gained an amazing support system and inspires her community every day. Because of that, we wanted to help her get the aid that she needs and deserves.</p>
      </div>
      <div @click="toggle" class="modal-placeholder flex__center pointer">
        <font-awesome-icon :icon="['fab', 'youtube']" size="4x" />
      </div>
    </div>
    <Modal v-if="toggled" @toggleModal="toggle" video="https://youtube.com/embed/SaHV0318fLE" />
  </section>
</template>

<script>
import Modal from "../../Parts/Modal";
export default {
  components: {
    Modal
  },
  data() {
    return {
      toggled: false
    };
  },
  methods: {
    toggle() {
      this.toggled = !this.toggled;
    }
  }
};
</script>

<style scoped lang="scss">
.featured {
  /* background: linear-gradient(to bottom right, $frost, $green); */
  background-color: $pine;
  padding: 75px 0;
}
.container,
.content {
  @include grid($cols: 1fr, $row-gap: 25px);
}
.content {
  align-items: center;
  h2,
  p {
    text-align: center;
  }
}
.modal-placeholder {
  width: 100%;
  max-width: 400px;
  height: 300px;
  margin: auto;
  background-image: url("https://cdn.fbhl.com/foundation/stock/brooklyn-check-min.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $pine;
  color: $black;
  transition: all 0.25s cubic-bezier(0.39, -0.56, 0.6, 1.56);
  border: 5px solid $frost;
  border-radius: 5px;
}
.modal-placeholder:hover {
  color: #ff0000;
  font-size: 1.5rem;
}
@media (min-width: $md) {
  .container {
    @include grid($cols: 1fr 1fr, $col-gap: 25px);
  }
  .content {
    grid-row-gap: 0px;
    h2,
    p {
      text-align: left;
    }
  }
}
</style>
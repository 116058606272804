<template>
  <section>
    <Featured />
    <Stories :stories="getLiveStories" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Featured from "./Featured";
import Stories from "./Stories";
export default {
  components: {
    Featured,
    Stories
  },
  metaInfo: {
    title: "Recipient Stories | The Giving Branch"
  },
  computed: {
    ...mapGetters(["getStories"]),
    getLiveStories() {
      if (!this.getStories) return [];
      return this.getStories.filter(story => story.status);
    }
  }
};
</script>

<style scoped lang="scss">
</style>
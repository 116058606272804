import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import site from "./modules/site";
import stories from "./modules/stories";
import team from "./modules/team";
import forms from "./modules/forms";

export default new Vuex.Store({
   modules: {
      site,
      stories,
      team,
      forms
   }
});
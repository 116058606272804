<template>
  <section class="form-container pos__rel">
    <Headings v-if="!submitting && !received" :active="currentStep" />
    <form class="form" @submit.prevent="submitForm">
      <div v-if="!acceptingResponses" class="form--disable text__center text__md">
        <p>Sorry! We are not currently accepting entries for the gift of transporation.</p>
        <!-- <p
          class="mt-15"
        >Please check back in March 2021 when our next application period will begin.</p>-->
      </div>
      <section v-if="currentStep === 1 && !submitting" key="1" class="section--questions">
        <label class="honeypot">
          Don't fill out this field*
          <input type="text" v-model="form.honeypot" />
        </label>

        <label :class="[errors.first.recipientName ? 'form--error' : null]">
          Full Name*
          <input
            v-model="form.recipientName"
            type="text"
            name="full name"
            placeholder="First Last"
          />
          <span
            v-if="errors.first.recipientName"
            class="form-sub--error"
          >{{errors.first.recipientName}}</span>
        </label>
        <p class="text__bold text__md">Recipient Address*</p>
        <div class="address">
          <label :class="[errors.first.recipientStreet ? 'form--error' : null]">
            Street*
            <input
              v-model="form.recipientAddress.street"
              type="text"
              name="recipient street address"
              placeholder="street address"
            />
            <span
              v-if="errors.first.recipientStreet"
              class="form-sub--error"
            >{{errors.first.recipientStreet}}</span>
          </label>
          <div class="address--double">
            <label :class="[errors.first.recipientCity ? 'form--error' : null]">
              City*
              <input
                v-model="form.recipientAddress.city"
                type="text"
                name="recipient city"
                placeholder="city"
              />
              <span
                v-if="errors.first.recipientCity"
                class="form-sub--error"
              >{{errors.first.recipientCity}}</span>
            </label>
            <label :class="[errors.first.recipientState ? 'form--error' : null]">
              State*
              <select v-model="form.recipientAddress.state" name="recipient state">
                <option value />
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
              <span
                v-if="errors.first.recipientState"
                class="form-sub--error"
              >{{errors.first.recipientState}}</span>
            </label>
          </div>
          <label :class="[errors.first.recipientZip ? 'form--error' : null]">
            Zip*
            <input
              v-model="form.recipientAddress.zip"
              v-mask="'#####'"
              type="tel"
              name="recipient zip"
              placeholder="12345"
            />
            <span
              v-if="errors.first.recipientZip"
              class="form-sub--error"
            >{{errors.first.recipientZip}}</span>
          </label>
        </div>

        <label :class="[errors.first.recipientEmail ? 'form--error' : null]">
          Email Address*
          <input
            v-model="form.recipientEmail"
            type="email"
            name="email address"
            placeholder="example@email.com"
          />
          <span
            v-if="errors.first.recipientEmail"
            class="form-sub--error"
          >{{errors.first.recipientEmail}}</span>
        </label>

        <label :class="[errors.first.recipientPhone ? 'form--error' : null]">
          Phone Number*
          <input
            v-model="form.recipientPhone"
            v-mask="'(###) ###-####'"
            type="tel"
            name="phone number"
            placeholder="(123) 456-7890"
          />
          <span
            v-if="errors.first.recipientPhone"
            class="form-sub--error"
          >{{errors.first.recipientPhone}}</span>
        </label>
        <div class="flex__right mt-15">
          <button class="btn btn__pine" @click.prevent="next">
            Next
            <font-awesome-icon icon="caret-right" size="1x" class="ml-10" />
          </button>
        </div>
      </section>

      <section v-if="currentStep === 2 && !submitting" key="2" class="section--questions">
        <label :class="[errors.second.hasDriversLicense ? 'form--error' : null]">
          Do you have a drivers license?*
          <select v-model="form.hasDriversLicense">
            <option :value="false">No</option>
            <option :value="true">Yes</option>
          </select>
          <span
            v-if="errors.second.hasDriversLicense"
            class="form-sub--error"
          >{{errors.second.hasDriversLicense}}</span>
        </label>

        <label :class="[errors.second.hasUnpaidTaxes ? 'form--error' : null]">
          Do you have any unpaid texes?*
          <select v-model="form.hasUnpaidTaxes">
            <option :value="false">No</option>
            <option :value="true">Yes</option>
          </select>
          <span
            v-if="errors.second.hasUnpaidTaxes"
            class="form-sub--error"
          >{{errors.second.hasUnpaidTaxes}}</span>
        </label>

        <label :class="[errors.second.travel ? 'form--error' : null]">
          Could you travel to Columbia, MO if needed?*
          <select v-model="form.travel">
            <option :value="false">No</option>
            <option :value="true">Yes</option>
          </select>
          <span v-if="errors.second.travel" class="form-sub--error">{{errors.second.travel}}</span>
        </label>

        <label :class="[errors.second.whoBenefit ? 'form--error' : null]">
          Who would directly benefit from getting this "Gift of Transportation"?*
          <textarea
            v-model="form.whoBenefit"
            name="hardship details"
            rows="4"
            placeholder="Your answer here..."
          />
          <span v-if="errors.second.whoBenefit" class="form-sub--error">{{errors.second.whoBenefit}}</span>
        </label>

        <label :class="[errors.second.details ? 'form--error' : null]">
          How would receiving his gift change your life?*
          <textarea
            v-model="form.details"
            name="hardship details"
            rows="4"
            placeholder="Your answer here..."
          />
          <span class="text__light" :class="charError">{{ characterCount }}/150 required characters</span>
        </label>

        <div class="flex__between mt-15">
          <button class="btn btn__yellow" @click="previous">
            <font-awesome-icon icon="caret-left" size="1x" class="mr-10" />Back
          </button>
          <button class="btn btn__pine" @click.prevent="submitForm">
            Submit
            <font-awesome-icon icon="caret-right" size="1x" class="ml-10" />
          </button>
        </div>
        <p v-if="errorMessage !== ''" class="submission--error">
          <span>
            Something went wrong when submitting.
            <br />Refresh the page and try again.
          </span>
          <span class="text__light">
            If the issue persists please reach out to
            <a
              href="mailto: ebressman@fbhl.com"
            >ebressman@fbhl.com</a>
          </span>
        </p>
      </section>

      <section v-if="submitting && !received" key="2" class="form-submitting">
        <font-awesome-icon icon="envelope" size="3x" class="text__green pulse mt-15" />
        <span class="text__xl">Please Wait</span>
        <p class="text__md">Your information is being sent.</p>
      </section>

      <section v-if="submitting && received" key="3" class="form-submitting">
        <font-awesome-icon icon="check" size="3x" class="text__green mt-15" />
        <span class="text__xl text__center">Thanks! Your submission was received.</span>
        <p
          class="text__md text__center"
        >Thank you for your submission! We will be reaching out to you within 48 hours requesting additional information.</p>
      </section>
    </form>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import Headings from "./CarHeadings";
export default {
  components: {
    Headings
  },
  data() {
    return {
      acceptingResponses: false,
      submitting: false,
      received: false,
      currentStep: 1,
      isValidated: true,
      errorMessage: "",
      form: {
        honeypot: "",
        recipientName: "",
        recipientEmail: "",
        recipientPhone: "",
        recipientAddress: {
          street: "",
          city: "",
          state: "",
          zip: ""
        },
        hasDriversLicense: null,
        hasUnpaidTaxes: null,
        travel: null,
        whoBenefit: "",
        details: "",
        submissionDate: moment(new Date()).format("MM-DD-YYYY h:mm a")
      },
      errors: {
        first: {
          recipientName: null,
          recipientStreet: null,
          recipientCity: null,
          recipientState: null,
          recipientZip: null,
          recipientPhone: null
        },
        second: {
          hasDriversLicense: null,
          hasUnpaidTaxes: null,
          travel: null,
          whoBenefit: null,
          details: null
        }
      }
    };
  },
  computed: {
    characterCount() {
      return this.form.details.length;
    },
    charError() {
      return this.characterCount < 150 ? "char--error" : null;
    }
  },
  methods: {
    ...mapActions(["startSubmitCar"]),
    emailTest(email) {
      const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return re.test(email);
    },
    validate() {
      let errors = this.errors;
      this.isValidated = true;
      if (this.currentStep === 1) {
        errors.first.recipientName =
          this.form.recipientName.length < 2
            ? "Please enter a full name"
            : null;
        errors.first.recipientStreet =
          this.form.recipientAddress.street.length < 1
            ? "Please enter a full street address"
            : null;
        errors.first.recipientCity =
          this.form.recipientAddress.city.length < 1
            ? "Please enter a city"
            : null;
        errors.first.recipientState = !this.form.recipientAddress.state
          ? "Please select a state"
          : null;
        errors.first.recipientZip =
          this.form.recipientAddress.zip.length < 5
            ? "Please enter a full zip code"
            : null;
        errors.first.recipientEmail = !this.emailTest(this.form.recipientEmail)
          ? "Please enter a valid email"
          : null;
        errors.first.recipientPhone =
          this.form.recipientPhone.length < 14
            ? "Please enter a full phone number"
            : null;
        for (let key in errors.first) {
          if (errors.first[key] === null) {
            this.isValidated = this.isValidated && true;
          } else {
            this.isValidated = this.isValidated && false;
          }
        }
      }
      if (this.currentStep === 2) {
        errors.second.hasDriversLicense =
          this.form.hasDriversLicense === null ? "Please select a value" : null;
        errors.second.hasUnpaidTaxes =
          this.form.hasUnpaidTaxes === null ? "Please select a value" : null;
        errors.second.travel =
          this.form.travel === null ? "Please select a value" : null;
        errors.second.whoBenefit =
          this.form.whoBenefit.length <= 2
            ? "Please provide a response for who would benefit"
            : null;
        errors.second.details =
          this.form.details.length < 150
            ? "You must enter at least 150 characters"
            : null;
        for (let key in errors.second) {
          if (errors.second[key] === null) {
            this.isValidated = this.isValidated && true;
          } else {
            this.isValidated = this.isValidated && false;
          }
        }
      }
    },
    previous() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    next() {
      this.validate();
      if (!this.isValidated) return;
      this.currentStep += 1;
    },
    async submitForm() {
      this.validate();
      if (!this.isValidated) return;
      this.submitting = true;
      if (this.form.honeypot !== "") {
        //if hidden field is filled out then must be a bot
        this.received = true;
        return;
      }
      try {
        await this.startSubmitCar(this.form);
        this.received = true;
        this.errorMessage = "";
      } catch (err) {
        this.errorMessage = `${err.message}, error was caught`;
        this.submitting = false;
      }
    }
  }
};
</script>

<style scoped lang='scss'>
.form--disable {
  @include pos-abs(
    $pos: absolute,
    $right: 0,
    $bottom: 0,
    $width: 100%,
    $height: 100%,
    $z: 2
  );
  padding: 50px;
  border-radius: 8px;
  background-color: $trans-black;
  @include flex($dir: column, $just: center, $align: center);
  * {
    color: $pistachio;
  }
}
.label-check {
  margin: 10px 0;
}
.dequal {
  @include grid($cols: 1fr, $row-gap: 15px);
  ul {
    @include grid($cols: 1fr, $row-gap: 10px);
    padding-left: 15px;
  }
}
</style>
const state = {
   menuToggled: false,
   images: [
      'https://cdn.fbhl.com/foundation/gallery/photo-1-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-2-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-3-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-4-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-5-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-6-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-7-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-8-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-9-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-10-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-11-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-12-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-13-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-14-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-15-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-16-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-17-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-18-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-19-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-20-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-21-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-22-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-23-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-24-min.png',
      'https://cdn.fbhl.com/foundation/gallery/photo-25-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-26-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-27-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-28-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-29-min.jpg',
      'https://cdn.fbhl.com/foundation/gallery/photo-30-min.jpg',
   ]
};

const getters = {
   getMenuToggled(state) {
      return state.menuToggled
   },
   getImages(state) {
      return state.images;
   }
};

const actions = {
   startSetMenuToggled({ commit, state }) {
      commit("SET_MENU_TOGGLED", !state.menuToggled);
   }
};

const mutations = {
   SET_MENU_TOGGLED(state, bool) {
      return state.menuToggled = bool;
   }
};

export default {
   state,
   getters,
   actions,
   mutations
}
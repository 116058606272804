<template>
  <section class="highlights">
    <div @click="toggle" class="modal-placeholder flex__center pointer">
      <font-awesome-icon :icon="['fab', 'youtube']" size="4x" />
    </div>
    <Modal v-if="toggled" @toggleModal="toggle" video="https://youtube.com/embed/s3olqfAM2k8" />
  </section>
</template>

<script>
import Modal from "../../Parts/Modal";
export default {
  components: {
    Modal
  },
  data() {
    return {
      toggled: false
    };
  },
  methods: {
    toggle() {
      this.toggled = !this.toggled;
    }
  }
};
</script>

<style scoped lang="scss">
.highlights {
  margin: auto;
  width: 90%;
  max-width: 650px;
  height: 400px;
  border: 5px solid $frost;
  margin-top: -100px;
  border-radius: 8px;
  margin-bottom: 50px;
  @include shadow;
}
.modal-placeholder {
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.fbhl.com/foundation/stock/million-reasons-graphic-crop.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $pine;
  color: $black;
  transition: all 0.25s cubic-bezier(0.39, -0.56, 0.6, 1.56);
}
.modal-placeholder:hover {
  color: #ff0000;
  font-size: 1.5rem;
}
</style>
<template>
  <section>
    <Banner />
    <Body :story="getActiveStory" />
    <Stories :stories="otherStories" />
  </section>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import Banner from "./Banner";
import Body from "./Body";
import Stories from "../Home/Stories";
export default {
  components: {
    Banner,
    Body,
    Stories
  },
  metaInfo: {
    title: `Story | The Giving Branch`
  },
  watch: {
    "$route.params.story": function() {
      this.changeParams();
    }
  },
  computed: {
    ...mapGetters(["getActiveStory", "getStories"]),
    previousStories() {
      return this.getStories.filter(
        story =>
          moment(story.post_date, "MM-DD-YYYY").format("YYYY-MM-DD") <=
            moment(this.getActiveStory.post_date, "MM-DD-YYYY").format(
              "YYYY-MM-DD"
            ) && story.url !== this.getActiveStory.url
      );
    },
    otherStories() {
      if (this.previousStories.length > 0) {
        return this.previousStories.slice(0, 3);
      }
      return this.getStories
        .filter(story => {
          return story.url !== this.getActiveStory.url;
        })
        .slice(0, 3);
    }
  },
  methods: {
    ...mapActions(["startSetActiveStory"]),
    async changeParams() {
      await this.startSetActiveStory(this.$route.params.story);
    }
  },
  mounted() {
    this.changeParams();
  }
};
</script>

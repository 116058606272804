<template>
  <section class="form-headings">
    <div class="rec flex__center sect" :class="[isActive(1) ? 'active' : null]">
      <p class="text__bold text__center">Information</p>
    </div>
    <div class="req flex__center sect" :class="[isActive(2) ? 'active' : null]">
      <p class="text__bold text__center">Contact</p>
    </div>
    <div class="hard flex__center sect" :class="[isActive(3) ? 'active' : null]">
      <p class="text__bold text__center">Qualification</p>
    </div>
  </section>
</template>

<script>
export default {
  props: ["active"],
  methods: {
    isActive(page) {
      return this.active === page;
    }
  }
};
</script>

<style scoped lang="scss">
$border: 2px solid lighten($black, 45%);
.form-headings {
  @include grid($cols: 1fr 1fr 1fr);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}
.sect {
  background-color: $green;
  border-bottom: $border;
  padding: 10px 0;
  box-shadow: inset 0px -26px 35px -20px $black;
  p {
    color: lighten($black, 45%);
  }
  &:nth-child(2) {
    border-left: $border;
    border-right: $border;
  }
}
.active {
  background: linear-gradient(to bottom, $pistachio, #fff);
  border-bottom: none;
  box-shadow: none;
  p {
    color: $pine;
  }
}
</style>
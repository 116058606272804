<template>
  <section>
    <div class="bg">
      <div class="container">
        <h1 class="text__center">Donation Request</h1>
        <p class="text__md text__center padding__sm">
          Anyone and any organization can apply! Once you fill out this form,
          your application will go to your regional Community Giving Team where your request will be reviewed.
          If you see a need, please feel free to apply for others.
          You may be contacted for more information.
        </p>
      </div>
    </div>
    <section class="container">
      <Donation />
      <Stories :stories="getLiveStories" />
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Donation from "../../Forms/Donation";
import Stories from "../Home/Stories";
export default {
  components: {
    Donation,
    Stories
  },
  metaInfo: {
    title: "Donation Request | The Giving Branch"
  },
  computed: {
    ...mapGetters(["getStories"]),
    getLiveStories() {
      if (!this.getStories) return [];
      return this.getStories.filter(story => story.status).slice(0, 3);
    }
  }
};
</script>

<style scoped lang="scss">
.bg {
  background: linear-gradient(rgba(38, 72, 34, 0.9), rgba(38, 72, 34, 0.8)),
    url("https://cdn.fbhl.com/posts/breaking-down-escrow-featured-image.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0 200px 0;
  * {
    color: $pistachio;
  }
}
</style>
<template>
  <header class="shadow">
    <div class="flex__between container">
      <router-link to="/" name="Giving Branch home page">
        <img
          class="logo"
          src="https://cdn.fbhl.com/foundation/logos/giving-branch-horizontal-crop.png"
          alt="Click to return to home page"
          width="100%"
          height="100%"
        />
      </router-link>
      <div class="right">
        <router-link
          to="/donation-request"
          name="request financial assistance"
          class="header-donate flex__center"
        >
          Donation
          Request
        </router-link>
        <div @click="startSetMenuToggled" class="menu-icon">
          <font-awesome-icon :icon="icon" size="2x" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["getMenuToggled"]),
    icon() {
      return this.getMenuToggled ? "times" : "bars";
    }
  },
  methods: {
    ...mapActions(["startSetMenuToggled"])
  }
};
</script>

<style scoped lang="scss">
header {
  background-color: $frost;
  height: 75px;
  @include pos-abs($pos: sticky, $z: 5);
  .container {
    height: 100%;
  }
}
.logo {
  width: 125px;
  height: 50px;
  margin: 5px 0;
}
.right {
  height: 100%;
  @include flex($just: flex-end, $align: center);
}
.header-donate {
  height: 100%;
  text-align: center;
  background-color: $pine;
  color: $powder;
  text-decoration: none;
  font-weight: bold;
  padding: 0px 15px;
  margin: 0 15px;
  line-height: 1.1rem;
}
.menu-icon {
  font-size: 1.4rem;
  color: $pine;
}
@media (min-width: $sm) {
  .header-donate {
    font-size: $text-md;
  }
}
</style>
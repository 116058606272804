<template>
  <section>
    <Logo />
    <Hats />
    <Requirements />
    <Inquire />
  </section>
</template>

<script>
import Logo from "./Logo";
import Hats from "./Hats";
import Requirements from "./Requirements";
import Inquire from "./Inquire";
export default {
  components: {
    Logo,
    Hats,
    Requirements,
    Inquire
  },
  metaInfo: {
    title: "Scholarship Info | The Giving Branch"
  }
};
</script>

<style scoped lang="scss">
</style>
<template>
  <section class="margin__md">
    <div class="container">
      <h2 class="text__center text__bold padding__sm">
        <span class="text__pine">Branching Out</span> in Our Communities
      </h2>
      <p
        class="text__center text__md"
      >Read stories of some of the people and organizations we have helped.</p>
      <div class="stories">
        <Story v-for="story in stories" :key="story.url" :story="story" />
      </div>
      <div class="flex__center">
        <router-link
          to="/recipient-stories"
          name="Read some stories of people we had helped"
          class="btn btn__pine btn__md flex__center"
        >
          Read More Stories
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import Story from "./Story";
export default {
  props: ["stories"],
  components: {
    Story
  }
};
</script>

<style scoped lang="scss">
p {
  padding-bottom: 50px;
}
.stories {
  @include flex($just: center, $wrap: wrap);
}
@media (min-width: $md) {
  .stories {
    grid-template-columns: 4fr 5fr;
    grid-template-areas: "recent featured";
  }
  .stories-recent {
    grid-template-columns: 1fr;
  }
  .featured .story {
    height: 100%;
  }
}
</style>
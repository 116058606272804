<template>
  <section>
    <Banner />
    <Impact />
    <Gallery />
  </section>
</template>

<script>
import Banner from "./Banner";
import Impact from "./Impact";
import Gallery from "./Gallery";
export default {
  components: {
    Banner,
    Impact,
    Gallery
  },
  metaInfo: {
    title: "Our Impact | The Giving Branch"
  }
};
</script>

<style scoped lang="scss">
</style>
<template>
  <section class="form-container">
    <Headings v-if="!submitting && !received" :active="currentStep" />
    <form class="contact-form form" @submit.prevent="submitForm">
      <section v-if="currentStep === 1 && !submitting" key="1" class="section--questions">
        <label class="honeypot">
          Don't fill out this field*
          <input type="text" v-model="form.honeypot" />
        </label>
        <label class="radio-group">
          Recipient Type*
          <div class="options">
            <label class="radio-label">
              <input
                id="individual"
                v-model="form.recipientType"
                type="radio"
                name="recipient for individual"
                value="individual"
                class="radio__md"
              />
              Individual
            </label>
            <label class="radio-label">
              <input
                id="group"
                v-model="form.recipientType"
                type="radio"
                name="recipient for group"
                value="group"
                class="radio__md"
              />
              Cause/Group
            </label>
          </div>
        </label>
        <label class="label-check">
          <input
            v-model="flatEmployee"
            type="checkbox"
            name="recipient is flat branch employee"
            id="flat employee"
            class="same_line check__lg"
            @click="isEmployee"
          />
          Recipient is a Flat Branch Mortgage Inc. employee
        </label>

        <label :class="[errors.first.recipientName ? 'form--error' : null]">
          Recipient Full Name*
          <input
            v-model="form.recipientName"
            type="text"
            name="recipient full name"
            placeholder="Full Name"
          />
          <span
            v-if="errors.first.recipientName"
            class="form-sub--error"
          >{{errors.first.recipientName}}</span>
        </label>

        <label :class="[errors.first.recipientEmail ? 'form--error' : null]">
          Recipient Email*
          <input
            v-model="form.recipientEmail"
            type="email"
            name="recipient email address"
            placeholder="email@domain.com"
          />
          <span
            v-if="errors.first.recipientEmail"
            class="form-sub--error"
          >{{errors.first.recipientEmail}}</span>
        </label>

        <label :class="[errors.first.recipientPhone ? 'form--error' : null]">
          Recipient Phone*
          <input
            v-model="form.recipientPhone"
            v-mask="'(###) ###-####'"
            type="tel"
            name="recipient phone number"
            placeholder="(123) 456-7890"
          />
          <span
            v-if="errors.first.recipientPhone"
            class="form-sub--error"
          >{{errors.first.recipientPhone}}</span>
        </label>

        <p class="text__bold text__md">Recipient Address*</p>
        <div class="address">
          <label :class="[errors.first.recipientStreet ? 'form--error' : null]">
            Street*
            <input
              v-model="form.recipientStreet"
              type="text"
              name="recipient street address"
              placeholder="street address"
            />
            <span
              v-if="errors.first.recipientStreet"
              class="form-sub--error"
            >{{errors.first.recipientStreet}}</span>
          </label>
          <div class="address--double">
            <label :class="[errors.first.recipientCity ? 'form--error' : null]">
              City*
              <input
                v-model="form.recipientCity"
                type="text"
                name="recipient city"
                placeholder="city"
              />
              <span
                v-if="errors.first.recipientCity"
                class="form-sub--error"
              >{{errors.first.recipientCity}}</span>
            </label>
            <label :class="[errors.first.recipientState ? 'form--error' : null]">
              State*
              <select v-model="form.recipientState" name="recipient state">
                <option value />
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
              <span
                v-if="errors.first.recipientState"
                class="form-sub--error"
              >{{errors.first.recipientState}}</span>
            </label>
          </div>
          <label :class="[errors.first.recipientZip ? 'form--error' : null]">
            Zip*
            <input
              v-model="form.recipientZip"
              v-mask="'#####'"
              type="tel"
              name="recipient zip"
              placeholder="12345"
            />
            <span
              v-if="errors.first.recipientZip"
              class="form-sub--error"
            >{{errors.first.recipientZip}}</span>
          </label>
        </div>

        <div class="flex__right mt-15">
          <button class="btn btn__pine" @click.prevent="next">
            Next
            <font-awesome-icon icon="caret-right" size="1x" class="ml-10" />
          </button>
        </div>
      </section>

      <section v-if="currentStep === 2 && !submitting" key="2" class="section--questions">
        <label class="label-check">
          <input
            v-model="checkSame"
            type="checkbox"
            name="requester same as recipient"
            id="same as"
            class="same_line check__lg"
            @click="sameAsAbove"
          />
          Requester is Recipient
        </label>

        <label :class="[errors.second.requesterName ? 'form--error' : null]">
          Requester Full Name*
          <input
            v-model="form.requesterName"
            type="text"
            name="requester full name"
            placeholder="Full Name"
          />
          <span
            v-if="errors.second.requesterName"
            class="form-sub--error"
          >{{errors.second.requesterName}}</span>
        </label>

        <label :class="[errors.second.requesterPhone ? 'form--error' : null]">
          Requester Phone*
          <input
            v-model="form.requesterPhone"
            v-mask="'(###) ###-####'"
            type="tel"
            name="requester phone"
            placeholder="(123) 456-7890"
          />
          <span
            v-if="errors.second.requesterPhone"
            class="form-sub--error"
          >{{errors.second.requesterPhone}}</span>
        </label>

        <label :class="[errors.second.requesterEmail ? 'form--error' : null]">
          Requester Email*
          <input
            v-model="form.requesterEmail"
            type="email"
            name="requester email address"
            placeholder="email@domain.com"
          />
          <span
            v-if="errors.second.requesterEmail"
            class="form-sub--error"
          >{{errors.second.requesterEmail}}</span>
        </label>

        <label :class="[errors.second.amount ? 'form--error' : null]">
          Request Amount*
          <input
            v-model="form.amount"
            type="number"
            name="request amount"
            step="any"
            placeholder="1800"
          />
          <span v-if="errors.second.amount" class="form-sub--error">{{errors.second.amount}}</span>
          <span
            v-show="form.amount >= 1500"
            class="span_warning"
          >Due to the request amount being $1500 or more, you may be contacted with additional forms to complete.</span>
        </label>

        <label v-if="show501C3" class="radio-group">
          Has 501(c)(3) Letter*
          <div id="options__line" class="options">
            <label class="radio-label">
              <input
                id="yes-501c3"
                v-model="form.determinationLetter"
                type="radio"
                name="501c3 yes"
                value="yes-501c3"
                class="radio__md"
              />
              Yes
            </label>
            <label class="radio-label">
              <input
                id="no-501c3"
                v-model="form.determinationLetter"
                type="radio"
                name="501c3 no"
                value="no-501c3"
                class="radio__md"
              />
              No
            </label>
          </div>
        </label>

        <label>
          Referred By
          <input
            v-model="form.referredBy"
            type="text"
            name="referred by"
            placeholder="name, group, ..."
          />
        </label>

        <div class="flex__between mt-15">
          <button class="btn btn__pistachio" @click.prevent="previous">
            <font-awesome-icon icon="caret-left" size="1x" class="mr-10" />Back
          </button>
          <button class="btn btn__pine" @click.prevent="next">
            Next
            <font-awesome-icon icon="caret-right" size="1x" class="ml-10" />
          </button>
        </div>
      </section>
      <section v-if="currentStep === 3 && !submitting" key="3" class="section--questions">
        <label :class="[errors.third.hardship ? 'form--error' : null]">
              Please Select Hardship Type
              <select v-model="form.hardship" name="hardship">
                <option value />
                <option value="Emergency">Emergency</option>
                <option value="Sudden Death">Sudden Death/Unexpected Death</option>
                <option value ="Medical">Medical</option>
                <option value ="Transitory">Transitory</option>
                <option value="Nonprofit">Nonprofit</option>
              </select>
              <span
                v-if="errors.third.hardship"
                class="form-sub--error"
              >{{errors.third.hardship}}</span>
            </label>
            <label :class="[errors.third.hardshipDate ? 'form--error' : null]">
              Date or timeline of hardship (Add dates if applicable)
              <input v-model="form.hardshipDate"
                      type="text"
                      name="hardship date"
                      placeholder=""/>
            
            <span
                v-if="errors.third.hardshipDate"
                class="form-sub--error"
              >{{errors.third.hardshipDate}}</span>

            </label>
        <label :class="[errors.third.details ? 'form--error' : null]">
          Please provide a detailed description of the {{ form.recipientType }}'s hardship.*
          <textarea
            v-model="form.details"
            name="hardship details"
            rows="4"
            placeholder="Your answer here..."
          />
          <span class="text__light" :class="charError">{{ characterCount }}/300 required characters</span>
          <span
                v-if="errors.third.details"
                class="form-sub--error"
              >{{errors.third.details}}</span>
        </label>
        

        <label :class="[errors.third.usage ? 'form--error' : null]">
          For what specifically would the donation be used?*
          <textarea
            v-model="form.usage"
            name="donation usage"
            rows="4"
            placeholder="Your answer here..."
          />
          <span class="text__light" :class="charError">{{ usageCount }}/1000</span>
          <span
                v-if="errors.third.usage"
                class="form-sub--error"
              >{{errors.third.usage}}</span>
        </label>
          <span
            class="span_warning"
          >Please note The Giving Branch cannot fund requests used to make mortgage payments.</span>
        

        <label>
          List any additional fundraising efforts
          <input
            v-model="form.fundraising"
            type="text"
            name="fundraising efforts"
            placeholder="website, gofundme, ..."
          />
          <span class="text__light">(e.g. website, benefits, etc)</span>
        </label>

        <div class="flex__between mt-15">
          <button class="btn btn__pistachio" @click.prevent="previous">
            <font-awesome-icon icon="caret-left" size="1x" class="mr-10" />Back
          </button>
          <button type="submit" class="btn btn__pine" @click.prevent="next">Submit</button>
        </div>
        <p v-if="errorMessage !== ''" class="submission--error">
          <span>
            Something went wrong when submitting.
            <br />Refresh the page and try again.
          </span>
          <span class="text__light">
            If the issue persists please reach out to
            <a
              href="mailto: ebressman@fbhl.com"
            >ebressman@fbhl.com</a>
          </span>
        </p>
      </section>

      <section v-if="submitting && !received" key="4" class="form-submitting">
        <font-awesome-icon icon="envelope" size="3x" class="text__green pulse mt-15" />
        <span class="text__xl">Please Wait</span>
        <p class="text__md">We're sending your request.</p>
      </section>

      <section v-if="submitting && received" key="5" class="form-submitting">
        <font-awesome-icon icon="check" size="3x" class="text__green mt-15" />
        <span class="text__xl text__center">Thanks! Your request was received.</span>
        <p
          class="text__md text__center"
        >All requests received will be reviewed in the following month. The committee meets on a monthly basis reviewing requests from the prior month. For example, if we receive your request on February 10th, it will be reviewed at our March meeting</p>
        <p class="text__md text__center">If you have questions in the meantime reach out to The Giving Branch Administrator at ebressman@fbhl.com.</p>
      </section>
    </form>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import Headings from "./DonationHeadings";
export default {
  components: {
    Headings
  },
  data() {
    return {
      submitting: false,
      received: false,
      currentStep: 1,
      isValidated: true,
      errorMessage: "",
      checkSame: false,
      form: {
        honeypot: "",
        recipientType: "individual",
        flatEmployee: false,
        recipientName: "",
        recipientStreet: "",
        recipientCity: "",
        recipientState: "",
        recipientZip: "",
        recipientEmail: "",
        recipientPhone: "",
        requesterName: "",
        requesterPhone: "",
        requesterEmail: "",
        amount: "",
        determinationLetter: "no-501c3",
        referredBy: "",
        details: "",
        usage: "",
        fundraising: "",
        hardship: "",
        hardshipDate: "",
        submissionDate: moment(new Date()).format("MM-DD-YYYY h:mm a")
      },
      errors: {
        first: {
          recipientName: null,
          recipientStreet: null,
          recipientCity: null,
          recipientState: null,
          recipientZip: null,
          recipientEmail: null,
          recipientPhone: null
        },
        second: {
          requesterName: null,
          requesterPhone: null,
          requesterEmail: null,
          amount: null
        },
        third: {
          details: null,
          usage: null,
          hardship: null,
          hardshipDate: null
        }
      }
    };
  },
  computed: {
    characterCount() {
      return this.form.details.length;
    },
    usageCount() {
      return this.form.usage.length;
    },
    charError() {
      return this.characterCount < 300 ? "char--error" : null;
    },
    today() {
      return moment().format("MM-DD-YYYY");
    },
    show501C3() {
      return this.form.recipientType === "group";
    }
  },
  methods: {
    ...mapActions(["startSubmitDonation"]),
    sameAsAbove(event) {
      if (event.target.checked) {
        this.form.requesterName = this.form.recipientName;
        this.form.requesterPhone = this.form.recipientPhone;
        this.form.requesterEmail = this.form.recipientEmail;
      } else {
        this.form.requesterName = "";
        this.form.requesterPhone = "";
        this.form.requesterEmail = "";
      }
    },
    isEmployee(event) {
    this.form.flatEmployee = event.target.checked;
    },
    
    previous() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    emailTest(email) {
      const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      return re.test(email);
    },
    validate() {
      let errors;
      this.isValidated = true;
      if (this.currentStep === 1) {
        errors = this.errors.first;
        errors.recipientName =
          this.form.recipientName.length < 2
            ? "Please enter a full name"
            : null;
        errors.recipientStreet =
          this.form.recipientStreet.length < 1
            ? "Please enter a full street address"
            : null;
        errors.recipientCity =
          this.form.recipientCity.length < 1 ? "Please enter a city" : null;
        errors.recipientState = !this.form.recipientState
          ? "Please select a state"
          : null;
        errors.recipientZip =
          this.form.recipientZip.length < 5
            ? "Please enter a full zip code"
            : null;
        errors.recipientEmail = !this.emailTest(this.form.recipientEmail)
          ? "Please enter a valid email"
          : null;
        errors.recipientPhone =
          this.form.recipientPhone.length < 14
            ? "Please enter a full phone number"
            : null;
        for (let key in errors) {
          if (errors[key] === null) {
            this.isValidated = this.isValidated && true;
          } else {
            this.isValidated = this.isValidated && false;
          }
        }
        return;
      }
      if (this.currentStep === 2) {
        errors = this.errors.second;
        errors.requesterName =
          this.form.requesterName.length < 2
            ? "Please enter a full name"
            : null;
        errors.requesterPhone =
          this.form.requesterPhone.length < 4
            ? "Please enter a full phone number"
            : null;
        errors.requesterEmail = !this.emailTest(this.form.requesterEmail)
          ? "Please enter a valid email"
          : null;
        errors.amount =
          this.form.amount <= 0 || this.form.amount === ""
            ? "Please enter a number more than 0"
            : null;
        for (let key in errors) {
          if (errors[key] === null) {
            this.isValidated = this.isValidated && true;
          } else {
            this.isValidated = this.isValidated && false;
          }
        }
        return;
      }
      if (this.currentStep === 3) {
        errors = this.errors.third;
        errors.hardship = !this.form.hardship
          ? "Please select a hardship"
          : null;
          errors.hardshipDate =
          this.form.hardshipDate < 3
            ? "Please enter at least 1 date"
            : null;
        errors.details =
          this.form.details.length < 300
            ? "You must enter at least 300 characters"
            : null;
        errors.usage =
          this.form.usage.length <= 0
            ? "Please enter more usage details"
            : null;
            errors.usage =
          this.form.usage.length > 1000
            ? "Character limit exceeds 1000"
            : null;
        for (let key in errors) {
          if (errors[key] === null) {
            this.isValidated = this.isValidated && true;
          } else {
            this.isValidated = this.isValidated && false;
          }
        }
      }
    },
    async next() {
      this.validate();
      if (!this.isValidated) return;
      if (this.currentStep === 3) {
        this.submitForm();
        return;
      }
      this.currentStep += 1;
    },
    async submitForm() {
      this.submitting = true;
      if (this.form.honeypot !== "") {
        //if hidden field is filled out then must be a bot
        this.received = true;
        return;
      }
      try {
        await this.startSubmitDonation(this.form);
        this.received = true;
        this.errorMessage = "";
      } catch (err) {
        this.errorMessage = `${err.message}, error was caught`;
        this.submitting = false;
      }
    }
  }
};
</script>

<style scoped lang='scss'>
</style>

<template>
  <section class="bg">
    <h2 class="text__center padding__sm">See Our Impact</h2>
    <section class="container">
      <!-- <simplebar class="gallery-overflow" data-simplebar-auto-hide="false"> -->
      <div class="gallery">
        <div class="group">
          <img
            v-for="img in groupOne"
            :key="img"
            :src="img"
            alt="Somebody helped by The Giving Branch"
            width="100%"
            height=" 100%"
          />
        </div>
        <div class="group">
          <img
            v-for="img in groupTwo"
            :key="img"
            :src="img"
            alt="Somebody helped by The Giving Branch"
            width="100%"
            height=" 100%"
          />
        </div>
        <div class="group">
          <img
            v-for="img in groupThree"
            :key="img"
            :src="img"
            alt="Somebody helped by The Giving Branch"
            width="100%"
            height=" 100%"
          />
        </div>
      </div>
      <scroll-loader v-if="canScroll" :loader-method="loadImages"></scroll-loader>
      <!-- </simplebar> -->
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      groupOne: [],
      groupTwo: [],
      groupThree: []
    };
  },
  computed: {
    ...mapGetters(["getImages"]),
    imgLoadCount() {
      return (
        this.groupOne.length + this.groupTwo.length + this.groupThree.length
      );
    },
    canScroll() {
      return this.imgLoadCount < this.getImages.length;
    }
  },
  methods: {
    loadImages() {
      if (this.imgLoadCount > this.getImages.length) return;
      if (window.innerWidth < 576) {
        if (this.imgLoadCount <= 0) {
          const images = this.getImages.slice(0, 4);
          this.groupOne.push(...images);
          return;
        }
        const length = this.groupOne.length;
        this.groupOne.push(...this.getImages.slice(length, length + 2));
        return;
      }
      if (window.innerWidth < 992) {
        if (this.imgLoadCount <= 0) {
          const images = this.getImages.slice(0, 6);
          this.groupOne.push(...images.slice(0, 3));
          this.groupTwo.push(...images.slice(3, 6));
          return;
        }
        const images = this.getImages.slice(
          this.imgLoadCount,
          this.imgLoadCount + 2
        );
        this.groupOne.push(images[0]);
        this.groupTwo.push(images[1]);
        return;
      }
      if (window.innerWidth >= 992) {
        if (this.imgLoadCount <= 0) {
          const images = this.getImages.slice(0, 9);
          this.groupOne.push(...images.slice(0, 3));
          this.groupTwo.push(...images.slice(3, 6));
          this.groupThree.push(...images.slice(6, 9));
          return;
        }
        const images = this.getImages.slice(
          this.imgLoadCount,
          this.imgLoadCount + 3
        );
        this.groupOne.push(images[0]);
        this.groupTwo.push(images[1]);
        this.groupThree.push(images[2]);
        return;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.bg {
  background: linear-gradient(rgba(38, 72, 34, 0.9), rgba(38, 72, 34, 0.9)),
    url("https://cdn.fbhl.com/foundation/videos/Fallback-Screen-Grab.jpg");
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  padding-bottom: 100px;
  /* background: linear-gradient(to bottom, $pine, $green, $pistachio, $powder);
  background-color: $green; */
}
h2 {
  color: $powder;
}
/* .gallery-overflow {
  height: 500px;
} */
.gallery {
  @include grid($cols: 1fr, $row-gap: 10px, $col-gap: 20px);
}
.group {
  @include grid($cols: 1fr, $row-gap: 10px);
  margin: 10px;
  height: fit-content;
}
.group:nth-child(2),
.group:nth-child(3) {
  display: none;
}
img {
  border: 2px solid $powder;
  max-width: 350px;
  margin: auto;
  width: 100%;
}
@media (min-width: $sm) {
  /* .gallery-overflow {
    height: 650px;
  } */
  .gallery {
    grid-template-columns: 1fr 1fr;
  }
  .group:nth-child(2) {
    display: grid;
  }
}
@media (min-width: $lg) {
  /* .gallery-overflow {
    height: 800px;
  } */
  .gallery {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .group:nth-child(3) {
    display: grid;
  }
}
</style>
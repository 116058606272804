<template>
  <section class="container">
    <section class="story-content">
      <section
        v-if="story.content_images && story.content_images.length > 0"
        class="story-content--images"
      >
        <img
          v-for="img in story.content_images"
          :key="img"
          :src="img"
          :alt="`${story.title} image`"
          width="100%"
          height="100%"
        />
      </section>
      <div class="story-content--text">
        <h2 class="padding__md">{{ story.title }}</h2>
        <div v-html="story.content" class="content-html"></div>
        <div class="flex__center margin__md">
          <router-link
            to="/donation-request"
            name="Request financial assistance"
            class="btn btn__pine btn__md"
          >Request Donation</router-link>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  props: ["story"]
};
</script>

<style scoped lang="scss">
.story-content {
  margin: auto;
  margin-top: -125px;
  margin-bottom: 75px;
  background-color: $cloud;
  border-radius: 5px;
  padding: 15px;
  @include shadow();
  max-width: 800px;
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 50px);
  grid-template-areas:
    "text"
    "images";
}
h2 {
  text-align: center;
  color: $pine;
  text-decoration: underline;
}
.story-content--text {
  grid-area: text;
}
.story-content--images {
  grid-area: images;
  @include grid($cols: 100%, $col-gap: 10px, $row-gap: 10px);
  height: fit-content;
  max-width: 300px;
  margin: auto;
}
.content-html {
  font-size: $text-md;
  @include grid($cols: 1fr, $row-gap: 15px);
}
@media (min-width: $sm) {
  .story-content--images {
    grid-template-columns: auto auto;
    max-width: 450px;
  }
}
@media (min-width: $lg) {
  .story-content {
    grid-template-columns: fit-content(200px) 1fr;
    grid-template-areas: "images text";
  }
  .story-content--images {
    grid-template-columns: 1fr;
  }
}
</style>
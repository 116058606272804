<template>
  <section class="margin__md">
    <section class="container stories">
      <Story v-for="story in stories" :key="story.url" :story="story" />
    </section>
  </section>
</template>

<script>
import Story from "../Home/Story";
export default {
  props: ["stories"],
  components: {
    Story
  }
};
</script>

<style scoped lang="scss">
.margin__md {
  margin-bottom: 100px;
}
.stories {
  @include grid($cols: 1fr, $row-gap: 75px, $col-gap: 25px, $just: center);
}
.story {
  margin: 0;
}
@media (min-width: $md) {
  .stories {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .story {
    width: 325px;
  }
}
@media (min-width: $xl) {
  .story {
    width: 350px;
  }
}
</style>
import Home from "./Home";
import Who from "./WhoWeAre"
import Stories from "./Stories";
import Scholarship from "./Scholarship";
import ScholarshipApp from "./ScholarshipApply"
import Story from "./Story";
import Impact from "./Impact";
import Donation from "./Donation";
import CarInfo from "./CarInfo";
import CarApply from "./CarApply";
import Contest from "./Contest";
import DPARules from "./DPARules";

export default {
   Home,
   Who,
   Stories,
   Story,
   Scholarship,
   Impact,
   Donation,
   ScholarshipApp,
   CarInfo,
   CarApply,
   Contest,
   DPARules
}
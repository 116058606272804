<template>
  <section style="height: auto;">
    <div class="bg">
      
      <div class="container">
        <h3 class="homeOwnership">NATIONAL HOMEOWNERSHIP MONTH</h3>
        <h1 class="text__center" style="color: white;">Down Payment Assistance</h1>
      </div>
      <br/>
      <br/>
      
      <h3 style="text-align: center; opacity: 1; ">To know if you qualify, be sure to check out the guidelines <strong> <a style="color: white;" href="https://foundation.flatbranchdev.com/dpa-rules">here</a></strong></h3>
    </div>

    <section>
      
      <iframe id="JotFormIFrame-231343792982060" title="Down Payment Assistance" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="https://form.jotform.com/231343792982060" frameborder="0" style="min-width:100%;max-width:100%;height: 1900px;border:none;" scrolling="yes" > </iframe>

    </section>
  </section>
</template>


<script>
export default {
  metaInfo: {
  title: "Down Payment Assistance | The Giving Branch"
  }
}</script>
<style scoped lang="scss">
.bg {
  background: url("https://cdn.fbhl.com/posts/down-payment-assistance.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0 200px 0;
  color: white;
  
}

.homeOwnership {
  background-color: goldenrod;
  text-align: center;
  border-left: 5px;
  border-right: 5px;
  color: white;
}
section {
  max-height: 100%;
  display: block;
  height: auto;
  background-color: #e78771;
}

h3
{
  color: white;
}


</style>
<template>
  <section class="highlight-reel--container">
    <div @click="toggle" class="modal-placeholder flex__center pointer">
      <font-awesome-icon :icon="['fab', 'youtube']" size="4x" />
    </div>
    <Modal v-if="toggled" @toggleModal="toggle" video="https://www.youtube.com/embed/3M_I9sXtsiU" />
  </section>
</template>

<script>
import Modal from "../../Parts/Modal";
export default {
  components: {
    Modal
  },
  data() {
    return {
      toggled: false
    };
  },
  methods: {
    toggle() {
      this.toggled = !this.toggled;
    }
  }
};
</script>

<style scoped lang="scss">
.highlight-reel--container {
  width: 100%;
  height: 100%;
  border: 5px solid $frost;
  border-radius: 8px;
  @include shadow;
}
.modal-placeholder {
  width: 100%;
  height: 100%;
  background-image: url("https://cdn.fbhl.com/foundation/stock/a-million-reasons-collage-min.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $pine;
  color: $black;
  transition: all 0.25s cubic-bezier(0.39, -0.56, 0.6, 1.56);
}
.modal-placeholder:hover {
  color: #ff0000;
  font-size: 1.5rem;
}
</style>
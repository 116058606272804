<template>
  <div class="video--banner">
    <video width="100%" height="100%" autoplay loop muted playsinline>
      <source :src="video" type="video/mp4" />
      <img :src="fallback" title="Your browser does not support this video" />
    </video>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: String,
      default: null
    },
    fallback: {
      type: String,
      default: null
    }
  }
};
</script>

<style scoped lang="scss">
.video--banner {
  height: 450px;
  overflow: hidden;
}
video {
  transform: scale(1.75, 2.5);
}
@media (min-width: $sm) {
  video {
    transform: scale(1.25, 1.5);
  }
}
@media (min-width: $md) {
  .video--banner {
    height: 550px;
  }
}
@media (min-width: $lg) {
  video {
    transform: scale(1.5, 1.5);
  }
}
@media (min-width: $xl) {
  video {
    transform: scale(2.5, 2);
  }
}
</style>
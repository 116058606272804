<template>
  <section>
    <div class="bg">
      <div class="container">
        <h1 class="text__center">Apply to Get A Car</h1>
        <p class="text__md text__center padding__sm">
          Please fill out the application with your information and we will reach out for further information within 48 hours.
          <br />One car will be gifted at the end of the submission period.
        </p>
      </div>
    </div>
    <section class="container">
      <CarForm />
    </section>
  </section>
</template>

<script>
import CarForm from "../../Forms/CarGive";
export default {
  components: {
    CarForm
  },
  metaInfo: {
    title: "Transportation Apply | The Giving Branch"
  }
};
</script>

<style scoped lang="scss">
.bg {
  background: linear-gradient(
      rgba(159, 212, 172, 0.8),
      rgba(159, 212, 172, 0.8)
    ),
    url("https://cdn.fbhl.com/foundation/stock/car-keys-handed-bg-min.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0 200px 0;
  * {
    color: $pine;
  }
}
</style>
<template>
  <section>
    <section class="bg">
      <section class="container">
        <h1 class="text__center">Gift of Transport</h1>
      </section>
    </section>
    <section class="container">
      <section class="grid">
        <div>
          <About />
          <Participation />
        </div>
        <Apply />
      </section>
    </section>
  </section>
</template>

<script>
import About from "./About";
import Participation from "./Participation";
import Apply from "./Apply";
export default {
  components: {
    About,
    Participation,
    Apply
  },
  metaInfo: {
    title: "Transportation Info | The Giving Branch"
  }
};
</script>

<style scoped lang="scss">
.bg {
  background: linear-gradient(rgba(38, 72, 34, 0.7), rgb(38, 72, 34, 0.7)),
    url("https://cdn.fbhl.com/foundation/stock/car-keys-handed-bg-min.jpeg");
  padding: 200px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
h1 {
  color: $powder;
}
.grid {
  @include grid($cols: 1fr, $row-gap: 25px, $col-gap: 25px);
  margin: 50px 0;
}
@media (min-width: $md) {
  .grid {
    grid-template-columns: 2fr 1fr;
  }
}
</style>
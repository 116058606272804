<template>
  <div class="modal">
    <div class="modal--content container">
      <div class="exit--icon flex__center" @click="toggleModal">
        <font-awesome-icon icon="times" size="2x" />
      </div>
      <iframe
        v-if="video"
        :src="video"
        autoplay="autoplay"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="allowfullscreen"
      ></iframe>
      <iframe
        v-else
        src="https://www.youtube.com/embed/sm1wujMpbmk"
        autoplay="autoplay"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="allowfullscreen"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: String,
      default: null
    }
  },
  methods: {
    toggleModal() {
      this.$emit("toggleModal", false);
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  @include pos-abs(
    $pos: null,
    $top: 0,
    $left: 0,
    $right: 0,
    $bottom: 0,
    $height: 100vh,
    $width: 100vw,
    $z: 5
  );
  background-color: $trans-black;
  @include flex($dir: column, $just: center, $align: center);
}
.modal--content {
  position: relative;
  height: 350px;
  width: 95%;
  max-width: 700px;
  iframe {
    height: 100%;
    width: 100%;
  }
}
.exit--icon {
  @include pos-abs(
    $pos: absolute,
    $top: -20px,
    $left: null,
    $right: 5px,
    $z: 6
  );
  color: #fff;
  background-color: $pine;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
@media (min-width: $md) {
  .modal--content {
    height: 400px;
  }
}
@media (min-width: $xl) {
  .modal--content {
    height: 450px;
    max-width: 750px;
  }
}
</style>
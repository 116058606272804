import moment from "moment";

const state = {
   activeStory: null,
   stories: [
      {
         title: "The Browning Family",
         content: `
            <p>
               The Browning family is fighting an unimaginable battle.
            </p>
            <p>
               Nearly every member of their family is positive with a gene that causes Osteosarcoma, 
               a bone cancer that creates tumors that weakens long bones within the body. 
               The family recently lost their sister Hailey to Osteosarcoma, 
               and 9 year old Hannah had to have her leg amputated – all while their father fights his battle with the disease. 
            </p>
            <p>
               Despite this, the family is full of life and courage. 
               They enjoy rural farming, as well as showing goats and sheep. 
               Their resilience is inspiring, and we knew we needed to support them in any way we could.
            <p>
               The contribution they received will be used for treatments and testing so their family can continue this fight.
            </p>`,
         status: true,
         meta_description: "",
         meta_title: "The Browning Family",
         post_date: "7-20-2021",
         featured_image: "https://cdn.fbhl.com/foundation/story-images-new/the-browning-family.jpeg",
         content_images: ["https://cdn.fbhl.com/foundation/story-images-new/the-browning-family.jpeg"],
         url: "the-browning-family",
         author: "giving branch",
         isFeatured: false
      },
      {
         title: "The Payton Family",
         content: `
            <p>
               The Payton family had two tragic events happen to them within weeks.
               The Payton's are a family of 5 boys and 2 newborn twins, a boy and a girl.
            </p>
            <p>
               On January 12th, Antonio was checking on their newborn twins. 
               Their 2 month old son was napping and was blue in the face. 
               Antonio is a Police Officer and started performing CPR while his wife, Precious, called 911. 
               Antonio said, "I have seen dead people and I promise was my son was gone."
               An ambulance, rushed their son to the hospitcal and stabilized him.
            </p>
            <p>
               Doctors explained he Rotavirus (inflammation of the stomach and intestines) along with a blockage to his lungs
               preventing him from properly breathing. 
               He has been in the hospital since that day, but is expected to be released shortly...happy and healthy.
            </p>
            <p>
               On January 31st, the Payton family was hit with another tragedy when their house caught on fire due to a 
               circuit breaker malfunction. 
               Antonio quickly got everyone out of the house and, once safely outside, he rushed back in to try to contain the fire. 
               Unfortunately, he was unable to contain the fire and called the fire department. 
            </p>
            <p>
               Thankfully, no one was injured, but the house was condemned. 
               They are very thankful that they are still a whole family and very appreciative for the kind gesture The Giving Branch has given.
            </p>`,
         status: true,
         meta_description: "",
         meta_title: "The Payton Family",
         post_date: "3-5-2021",
         featured_image: "https://cdn.fbhl.com/foundation/story-images-new/the-payton-family.jpg",
         content_images: ["https://cdn.fbhl.com/foundation/story-images-new/the-payton-family.jpg"],
         url: "the-payton-family",
         author: "giving branch",
         isFeatured: false
      },
      {
         title: "St. Louis Crisis Nursery",
         content: `
            <p>Seven-year-old Daniel was at the Saint Louis Crisis Nursery with his little brother and sister. 
            They had been living in the car with their mother for three days before she called the Crisis Nursery</p>
            <p>Mom dropped the children at the Crisis Nursery while she went to secure permanent housing, using resources given to her by Crisis Nursery Staff.</p>
            <p>The counselor at the Nursery put a plate of food in front of Daniel at dinner time, and he immediately began dividing it to share with his siblings.</p>
            <p>The counselor told Daniel that his brother and sister would each get their own plate and he did not have to share. </p>
            <p>Daniel couldn't believe it.
            The counselor could see Daniel's face brighten as the weight of concern and responsibility was lifted from his little seven-year-old shoulders. </p>
            <p>At the Saint Louis Crisis Nursery, we let kids be kids while we help the grown-ups work through whatever crisis is occurring in the family. 
            It is only because of amazing supporters, like those at The Giving Branch, that we can keep our doors open for kids like Daniel.</p>`,
         status: true,
         meta_description: "",
         meta_title: "St. Louis Crisis Nursery",
         post_date: "10-15-2020",
         featured_image: "https://cdn.fbhl.com/foundation/story-images-new/stl-crisis-nursery.png",
         content_images: ["https://cdn.fbhl.com/foundation/story-images-new/stl-crisis-nursery.png"],
         url: "stl-crisis-nursery",
         author: "giving branch",
         isFeatured: false
      },
      {
         title: "Katie, Kevin, and Patty",
         content: `
            <p>On April 14, 2020 our lives were permanently altered. My dad, Kevin (58), was stopping by my house after work to pick up dinner for him and my mome. 
            When he came in I noticed that his speech was slurred and confused. I figured he had a tooth pulled that day. 
            When he said he hadn't had any dental work, I suspected he was experiencing a strong. 
            Being no stranger to the effects of a stroke, my grandpa had one in 2013, I understood the importance of acting fast. 
            While my dad would not allow me to call an ambulance, he did agree to let me drive him home and call my mom. 
            Her swift action ensured an ambulance was waiting at their house when we arrived. 
            Paramedics and the emergency room doctor agreed that the presentation was stroke-like. 
            As time passed, we knew my dad was having a CT scan and was going to be administered a stroke drug to reverse the long term effects.</p>
            <p>It was then that we got the call.</p>
            <p>My mom answered and the ER doctor stated that it was not a stroke, but my dad had a mass on his brain. 
            At that moment, I saw the light and home in my mom's eyes disappear and a sound escaped her body that I hope to never hear again. 
            </p>
            <p>Because of Covid-19 my dad was alone. He was alone when the doctor told him he had a mass on his brain. 
            He was alone the next morning when the neurologist confirmed the suspected brain cancer. He was alone when he was told that his life expectancy would be 12-18 months.</p>
            <p>Since that day, my dad has been diagnosed with glioblastoma grade IV. He can no longer work. He can no longer drive. 
            His speech is permanently affected. He has had surgery for tumor resection, and underwent six weeks of chemotherapy and radiation. 
            Fortunately, his recent scan showed no regrowth and he is now utilizing a device called Optune which research shows can extend his life significantly. 
            However, the Optune comes with a cost of $21,000 per month. 
            While insurance will cover most of this, my family is incurring an expense for this devise.</p>
            <p>Knowing our tough financial times, a long time family friend, Karen Rainwater, reach out to my mom regarding The Giving Branch. 
            Karen was sharing our story with her neighbor Mary Dukate who suggested we complete an application. 
            Being approved for $6,500 was the best new 2020 could bring. 
            The Optune device will consume the generous donation from The Giving Branch.</p>
            <p>With the help of The Giving Branch my family can afford this life extending device. 
            With the help of The Giving Branch my dad is no longer alone. And with the help of The Giving Branch the light and home has returned to my mother's eyes. 
            From the bottom of my family's hear we thank you for giving us this gift, and allowing us some financial peace during this time</p>`,
         status: true,
         meta_description: "",
         meta_title: "Katie, Kevin, & Patty",
         post_date: "8-31-2020",
         featured_image: "https://cdn.fbhl.com/foundation/tgb-stories-default-preview-splash-img-min.jpg",
         content_images: ["https://cdn.fbhl.com/foundation/tgb-stories-default-preview-splash-img-min.jpg"],
         url: "katie-kevin-patty",
         author: "giving branch",
         isFeatured: false
      },
      {
         title: "Lana",
         content: `
            <p>There are no words to express the kindness and generosity demonstrated to my family and 
            I from the donation was was recently presented to us by Justin Pittman on behalf of The Giving Branch.
            I had no idea that Justin had submitted my name to be a recipient from The Giving Branch.</p>
            <p>Between battling breast cancer over the past year and now Covid-19, it has been quite an adventure to say the least. 
            In addition to the tress of cancer also comes the financial stress from not being able to work real estate like I normally have over the last eighteen years. 
            Cancer is, well, there are no words to describe the horror of cancer. 
            I think my faith, warped sense of humor, positive attitude, and overwhelming support group got me through. 
            So many people helped us along the way with prayers, encouraging cards and texts, gifts, errands, meals, etc. 
            I will be forever grateful to my family and friends, my church, my KW family, and to each and every person who joined me in my journey. </p>
            <p>I am no stranger to The Giving Branch as it helped a client of mine who was victim to the 2019 flood disaster in Oklahoma. 
            The Giving Branch generously came to the aid of this dear family. 
            Thank you for the bottom of my heart. 
            The Giving Branch and Justin Pittman are very special and my family and I will be forever grateful. </p>
            <p>Thank God I just finished my last chemo and I am cancer free!</p>`,
         status: true,
         meta_description: "",
         meta_title: "Lana",
         post_date: "8-4-2020",
         featured_image: "https://cdn.fbhl.com/foundation/story-images-new/lana-second.jpg",
         content_images: ["https://cdn.fbhl.com/foundation/story-images-new/lana-first.jpg",
            "https://cdn.fbhl.com/foundation/story-images-new/lana-second.jpg",
            "https://cdn.fbhl.com/foundation/story-images-new/lana-third.jpg"],
         url: "lana",
         author: "giving branch",
         isFeatured: false
      },
      {
         title: "Chloe's Treatment",
         content: `
            <p>We would like to express our deepest gratitude for the donation we received.</p>
            <p>The funds have helped with travel and chemotherapy treatment expenses for our 19-month-old daughter, Chloe.</p>
            <p>Chloe is currently receiving treatment at Children's Mercy, but will soon be travelling to MD Anderson for proton beam therapy that is not currently offered in Kansas City.
            Words cannot express how touched we are by your kindness.</p>`,
         status: true,
         meta_description: "",
         meta_title: "Chloe's Treatment",
         post_date: "7-21-2020",
         featured_image: "https://cdn.fbhl.com/foundation/story-images-new/chloe-treatment-third.jpg",
         content_images: ["https://cdn.fbhl.com/foundation/story-images-new/chloe-treatment-first.jpg",
            "https://cdn.fbhl.com/foundation/story-images-new/chloe-treatment-second.jpg",
            "https://cdn.fbhl.com/foundation/story-images-new/chloe-treatment-third.jpg"],
         url: "chloe-treatment",
         author: "giving branch",
         isFeatured: false
      },
      {
         title: "Covid Relief",
         content: `
            <p>The last few months have been extremely difficult.
            It has been a time that we have been challenged and changed.</p>
            <p> My mother lost her job due to Covid-19 and we were extremely disheartened. 
            I heard about your organization through Facebook, and I decided to ask for support. </p>
            <p>Upon receiving your email, I told my mom that there would be monetary support and she was excited. 
            We could not believe that there are people like you that are gracious and supportive. 
            We have been a receiver of your blessing. We have been blessed and are now sharing with others about your loving support. 
            We can't thank you enough!</p>
            <p>We are so blessed and uncontrollably surprised by your gracious support. We love you.</p>`,
         status: true,
         meta_description: "",
         meta_title: "Covid Relief",
         post_date: "7-9-2020",
         featured_image: "https://cdn.fbhl.com/foundation/tgb-stories-default-preview-splash-img-min.jpg",
         // content_images: ["https://cdn.fbhl.com/foundation/tgb-stories-default-preview-splash-img-min.jpg"],
         url: "covid-relief",
         author: "giving branch",
         isFeatured: false
      },
      {
         title: "Primrose Hill COVID Relief",
         content: `
            <p>During COVID-19 restrictions, Primrose Hill was deemed to be an essential business. 
            We were able to remain open, but many of our forms of funding had been shut down, making things difficult.</p>
            <p>The donation to our program during such a critical time was an incredible blessing. 
            We are so grateful that you though of us!</p>
            <p>Thank you all at The Giving Branch for thinking of the hardships in your community and doing your part to reach out and offer help!</p>`,
         status: true,
         meta_description: "",
         meta_title: "Primrose Hill COVID Relief",
         post_date: "7-9-2020",
         featured_image: "https://cdn.fbhl.com/foundation/story-images-new/primrose-hill-covid-relief.jpg",
         content_images: ["https://cdn.fbhl.com/foundation/story-images-new/primrose-hill-covid-relief.jpg"],
         url: "primrose-hill-covid-relief",
         author: "giving branch",
         isFeatured: false
      },
      {
         title: "Schoem Family",
         content: `
            <p>Too many times lately I have been found speechless. 
            When someone tells you that your perfectly healthy two year old son has a large tumor in his stomach and that it is cancer, speechless. 
            When they tell you he needs surgery to remove it and then four rounds of chemotherapy to hopefully get it all, speechless. 
            To go through it all during a worldwide pandemic and see it unfold in the chaos you are already living in, still to this day, leaves us speechless. 
            What has helped us through and has given us so much light in a world  that suddenly seems dark, is the generosity of those who surround us and those who have never met us. 
            In a time when hugs are really needed and can't be given, 
            the generosity and kindness of strangers has particularly touched my husband and me in a way that makes us just believe that there is a kind and giving world out there in all of this uncertainty.</p>
            <p>When we received the funds from The Giving Branch, we again were left speechless. 
            We were absolutely amazed that this kind of generosity, this kind of company, this gift of hope had found us. </p>
            <p>It has meant more than I can begin to put into words. 
            The gift has meant less stress when we get the mail and see 5 more medical bills. 
            It has meant less stress when I can't work because of another complication and hospital stay. 
            It has allowed our full focus to be where it needs to be, on our son and his recovery. 
            My family and I would like to sincerely thank those who made our donation possible.</p>
            <p>Since our son's diagnosis, he has spent 26 days in the hospital that have included stays for his surgery, a virus / infection and pneumonia. 
            We are  happy to announce that he has recovered from all of those illnesses and is entering into what we hope and pray is his last round of chemotherapy. 
            His prognosis is good and we hope he will go on to lead a normal life and never remember even a moment of his illness.</p>`,
         status: true,
         meta_description: "",
         meta_title: "Schoem Family",
         post_date: "6-3-2020",
         featured_image: "https://cdn.fbhl.com/foundation/story-images-new/schoem-family-first.png",
         content_images: ["https://cdn.fbhl.com/foundation/story-images-new/schoem-family-first.png",
            "https://cdn.fbhl.com/foundation/story-images-new/schoem-family-second.jpg"],
         url: "schoem-family",
         author: "giving branch",
         isFeatured: false
      },
      {
         title: "Lovins Family",
         content: `
            <p>Words cannot express how grateful we were for the generous monetary donation we received from your organization during our time of need. 
            The donation enabled me to stay at home with our babies instead of rushing back to work. 
            Doctors have even told me that me spending so much time when them helped their development and progress so much. 
            It is all because of you.
            I cannot tell you the relief we felt knowing we did not have to worry about financial stress; we could fully focus on our two blessings.</p>
            <p>Kolt and Kinsley have come a long way since they were born in June. 
            We spent 72 days in the hospital, 69 days of which were spent in the NICU. 
            We have been home over two months. 
            Kolt now weighs 13 pounds (he weighed 3lbs, 6oz when he was born) and Kinsley weight over 11 pounds (she weighed only 2lbs, 14oz when she was born)!</p>
            <p>Their pediatrician is amazed and very pleased with their progress. 
            Their strength and resilience amaze us every day. 
            So many people prayed for us during this time and helped us. 
            The results are in the photo above. We are so blessed and so thankful. </p>
            <p>Again, thank you so much for this wonderful gift. 
            It means more to our family than you will ever know.</p>`,
         status: true,
         meta_description: "",
         meta_title: "Lovins Family",
         post_date: "11-22-2019",
         featured_image: "https://cdn.fbhl.com/foundation/tgb-stories-default-preview-splash-img-min.jpg",
         // content_images: ["https://cdn.fbhl.com/foundation/tgb-stories-default-preview-splash-img-min.jpg"],
         url: "lovins-family",
         author: "giving branch",
         isFeatured: false
      }
   ]
}

const getters = {
   getStories(state) {
      return state.stories.sort((a, b) => {
         const first = moment(a.post_date, "MM-DD-YYYY").format("YYYY-MM-DD");
         const second = moment(b.post_date, "MM-DD-YYYY").format("YYYY-MM-DD");
         if (first < second) return 1;
         if (first > second) return -1;
         return 0;
      });
   },
   getActiveStory(state) {
      return state.stories.filter(story => story.url === state.activeStory)[0];
   }
}

const actions = {
   startSetActiveStory({ commit }, url) {
      commit("SET_ACTIVE_STORY", url);
   }
}

const mutations = {
   SET_ACTIVE_STORY(state, url) {
      state.activeStory = url;
   }
}

export default {
   state,
   getters,
   actions,
   mutations
}
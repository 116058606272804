import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Pages from "../components/Pages";

Vue.use(VueRouter);

export default new VueRouter({
   mode: "history",
   scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
         return savedPosition;
      }
      if (to.hash) {
         return {
            selector: to.hash,
            bahavior: 'smooth'
         }
      }
      return {
         x: 0,
         y: 0,
         behavior: 'smooth'
      }
   },
   routes: [
      {
         path: "/",
         name: "home",
         component: Pages.Home
      },
      {
         path: "/recipient-stories",
         name: "stories",
         component: Pages.Stories
      },
      {
         path: "/recipient-stories/:story",
         name: "story",
         component: Pages.Story,
         beforeEnter: async (to, from, next) => {
            if (to.params.story) {
               await store.dispatch("startSetActiveStory", to.params.story);
               return next();
            }
            return next(from.fullPath);
         },
         beforeRouteUpdate: async (to, from, next) => {
            if (to.params.story) {
               await store.dispatch("startSetActiveStory", to.params.story);
               return next();
            }
            return next(from.fullPath);
         }
      },
      {
         path: "/who-we-are",
         name: "who-we-are",
         component: Pages.Who
      },
      {
         path: "/our-impact",
         name: "our-impact",
         component: Pages.Impact
      },
      {
         path: "/scholarship",
         name: "scholarship",
         component: Pages.Scholarship
      },
      {
         path: "/scholarship/apply",
         name: "scholarship-form",
         component: Pages.ScholarshipApp
      },
      {
         path: "/donation-request",
         name: "help",
         component: Pages.Donation
      },
      {
         path: "/car-giveaway",
         name: "car-giveaway",
         component: Pages.CarInfo
      },
      {
         path: "/car-giveaway/apply",
         name: "car-giveaway-apply",
         component: Pages.CarApply
      },
      // {
      //    path: "/down-payment-assistance",
      //    name: "down-payment-assistance",
      //    component: Pages.Contest
      // },
      // {
      //    path: "/dpa-rules",
      //    name: "dpa-rules",
      //    component: Pages.DPARules
      // },
      {
         path: "*",
         name: "404",
         redirect: "/"
      }
   ]
});
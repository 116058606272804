
const state = {
   regions: {
      como: [
         {
            img: "https://cdn.fbhl.com/foundation/team-members/COMO/COMO-amanda-cavanaugh-crop.jpg",
            name: 'Amanda Cavanaugh',
            region: 'Columbia, MO'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/COMO/COMO-brittany-costanza-crop.jpg",
            name: 'Brittany Costanza',
            region: 'Columbia, MO'
         }
      ],
      il: [
         {
            img: "https://cdn.fbhl.com/foundation/team-members/IL/IL-fred-weber-crop.jpg",
            name: 'Fred Weber',
            region: 'Illinois'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/IL/IL-justin-gensler-crop.jpg",
            name: 'Justin Gensler',
            region: 'Illinois'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/IL/IL-lori-hines-crop.jpg",
            name: 'Lori Hines',
            region: 'Illinois'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/IL/IL-noelle-barnes-crop.jpg",
            name: 'Noelle Barnes',
            region: 'Illinois'
         }
      ],
      kc: [
         {
            img: "https://cdn.fbhl.com/foundation/team-members/KC/KC-beth-langston-crop.jpg",
            name: 'Beth Langston',
            region: 'Kansas City, MO'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/KC/KC-beverly-sneed-crop.png",
            name: 'Beverly Sneed',
            region: 'Kansas City, MO'
         }
      ],
      midmo: [
         {
            img: "https://cdn.fbhl.com/foundation/team-members/Mid-Mo/Mid-Mo-brandi-martin-crop.jpg",
            name: 'Brandi Martin',
            region: 'Mid-Missouri'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/Mid-Mo/Mid-Mo-lindsey-dehn-crop.jpg",
            name: 'Lindsey Dehn',
            region: 'Mid-Missouri'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/Mid-Mo/Mid-Mo-tina-howard-crop.jpg",
            name: 'Tina Howard',
            region: 'Mid-Missouri'
         }
      ],
      okar: [
         {
            img: "https://cdn.fbhl.com/foundation/team-members/OK-AR/OK-AR-alex-higeons-crop.jpg",
            name: 'Alex Higeons',
            region: 'Oklahoma & Arkansas'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/OK-AR/OK-AR-fernando-adame-crop.jpg",
            name: 'Fernando Adame',
            region: 'Oklahoma & Arkansas'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/OK-AR/OK-AR-misty-johnson-crop.jpg",
            name: 'Misty Johnson',
            region: 'Oklahoma & Arkansas'
         }
      ],
      southern: [
         {
            img: "https://cdn.fbhl.com/foundation/team-members/Southern/Southern-christina-ferrante-crop.jpg",
            name: 'Christina Ferrante',
            region: 'Southern Missouri'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/Southern/Southern-jennifer-cook-crop.jpg",
            name: 'Jennifer Cook',
            region: 'Southern Missouri'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/Southern/Southern-lisa-stayton-crop.jpg",
            name: 'Lisa Stayton',
            region: 'Southern Missouri'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/Southern/Southern-susan-theroff-crop.jpg",
            name: 'Susan Theroff',
            region: 'Southern Missouri'
         }
      ],
      stl: [
         {
            img: "https://cdn.fbhl.com/foundation/team-members/STL/STL-alicia-miller-crop.jpg",
            name: 'Alicia Miller',
            region: 'St. Louis, MO'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/STL/STL-emily-peggs-crop.jpg",
            name: 'Emily Peggs',
            region: 'St. Louis, MO'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/STL/STL-jessica-patterson-crop.png",
            name: 'Jessica Patterson',
            region: 'St. Louis, MO'
         },
         {
            img: "https://cdn.fbhl.com/foundation/team-members/STL/STL-missy-palitzsch-crop.jpg",
            name: 'Missy Palitzsch',
            region: 'St. Louis, MO'
         }
      ]
   }
};

const getters = {
   getRandomTeamMembers(state) {
      let imgArr = [];
      let random;
      for (let key in state.regions) {
         random = Math.floor(Math.random() * (state.regions[key].length));
         imgArr.push(state.regions[key][random]);
      }
      return imgArr;
   }
};

export default {
   state,
   getters
}
<template>
  <div class="story pos__rel">
    <div class="bg-image pos__rel" :style="`background-image: url('${story.featured_image}');`">
      <div class="title flex__between">
        <div class="icon">
          <font-awesome-icon icon="book" size="lg" />
        </div>
        <p class="text__bold text__left">{{story.title}}</p>
      </div>
    </div>
    <div class="text-padding">
      <div class="short-text" v-html="story.content"></div>
    </div>
    <div class="read-more">
      <router-link :to="`/recipient-stories/${story.url}`" name="Read full story" class="shadow">
        Read More
        <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["story"]
};
</script>

<style scoped lang="scss">
.story {
  width: 275px;
  background-color: $frost;
  @include shadow;
  margin: 0px 25px 75px 25px;
}
.bg-image {
  height: 200px;
  overflow: hidden;
  background-color: $green;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.title {
  @include pos-abs($pos: absolute, $top: null, $bottom: 0, $width: 100%, $z: 0);
  background-color: $pine;
  * {
    color: $powder;
  }
  .icon {
    padding: 15px;
    background-color: $black;
  }
  p {
    font-size: $text-md;
    font-weight: normal;
    line-height: 1.2rem;
    padding: 0px 8px;
  }
}
.text-padding {
  padding: 10px;
}
.short-text {
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
.read-more {
  @include pos-abs($pos: absolute, $top: 100%, $width: 100%, $z: 0);
  border-top: 3px solid $green;
  * {
    color: $powder;
  }
  @include flex($just: flex-end, $align: center);
  a {
    background-color: $green;
    padding: 5px 12px;
    text-decoration: none;
  }
}
</style>
<template>
  <section>
    <VideoBanner
      video="https://cdn.fbhl.com/foundation/videos/TGB-Website-Video-Banner-No-Audio-compressed.mp4"
      fallback="https://cdn.fbhl.com/foundation/videos/Fallback-Screen-Grab.jpg"
    />
    <Stance />
    <Forms />
    <Hardships />
    <Stats />
    <Stories :stories="getLiveStories" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import VideoBanner from "../../Parts/Banners/VideoBanner";
import Stance from "./Stance";
import Forms from "./Forms";
import Stats from "./Stats";
import Stories from "./Stories";
import Hardships from "./Hardships.vue"
export default {
  components: {
    VideoBanner,
    Stance,
    Forms,
    Stats,
    Stories,
    Hardships
  },
  metaInfo: {
    title: "Home | The Giving Branch"
  },
  computed: {
    ...mapGetters(["getStories"]),
    getLiveStories() {
      if (!this.getStories) return [];
      return this.getStories.filter(story => story.status).slice(0, 3);
    }
  }
};
</script>

<style scoped>
</style>
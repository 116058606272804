<template>
  <div id="app">
    <div class="main" :class="mainClass">
      <Header />
      <router-view></router-view>
      <Footer />
    </div>
    <transition name="menu">
      <NavList v-if="getMenuToggled" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "./components/Parts/Header";
import NavList from "./components/Parts/Navigation/NavList";
import Footer from "./components/Parts/Footer";
export default {
  name: "App",
  components: {
    Header,
    NavList,
    Footer
  },
  computed: {
    ...mapGetters(["getMenuToggled"]),
    mainClass() {
      return this.getMenuToggled ? "slide" : null;
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  transition: transform 0.25s ease-in-out;
}
.slide {
  transform: translateX(-$nav-width);
  transition: transform 0.25s ease-in-out;
}
</style>
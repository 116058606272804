<template>
    <section style="height: auto;">
      <div class="bg">
        <div class="container">       
          <h1 class="text__center" style="color: white;">Giving Branch Housing Grant</h1>
        </div>
      </div>     
      <section class="table-container">
        <table>
      <tbody>
        <tr>
          <th>Usage</th>
          <td>To make homeownership more attainable for low to moderate income first-time homebuyer households through down payment and/or closing cost assistance to qualified households.</td>
        </tr>
        <tr>
          <th>First-Time Homebuyer</th>
          <td>Definition: An individual is considered a first-time homebuyer who (1) is purchasing the subject property; (2) will reside in the subject property as a principal residence; and (3) had no ownership interest (sole or joint) in a residential property during the 3-year period preceding the date of the purchase of the subject property. In addition, an individual who is a displaced homemaker or single parent also will be considered a first-time homebuyer if he or she had no ownership interest in a principal residence (other than a joint ownership interest with a spouse) during the preceding 3-year time period.</td>
        </tr>
        <tr>
          <th>Qualifying Mortgage</th>
          <td>Must have preapproval letter qualifying borrower for an FHA, USDA or Conventional (Home possible/HomeReady) loan accepting down payment assistance from lender.</td>
        </tr>        
        <tr>
          <th>Program Details</th>
          <td>Program runs from June 1st until June 30th. Funds are allocated on first come, first serve basis.</td>
        </tr>
        <tr>
          <th>Maximum LTV/CLTV</th>
          <td>Follow applicable agency guidelines.</td>
        </tr>
        <tr>
          <th>Minimum Credit Score</th>
          <td>620 minimum credit score for all applicants.</td>
        </tr>
        <tr>
          <th>Grant Amount</th>
          <td>Up to 3% of purchase price, not to exceed $3,000. *100% Forgivable</td>
        </tr>
        <tr>
          <th>Loan Purpose</th>
          <td>Purchase only.</td>
        </tr>
        <tr>
          <th>Property & Occupancy Types</th>
          <td>Must be 1 unit owner occupied.</td>
        </tr>
        <tr>
          <th>Trusts</th>
          <td>Title may not be held in the name of a trust.</td>
        </tr>
        <tr>
          <th>Amortization / Term</th>
          <td>First mortgage must be 30-year fixed rate term.</td>
        </tr>
        <tr>
          <th>Debt Ratio</th>
          <td>Max 45% * if above must follow agency guidelines.</td>
        </tr>
        <tr>
          <th>Income</th>
          <td>Household income must be at or under 80% Area Medium Income this excludes dependents. <a href="https://ami-lookup-tool.fanniemae.com/amilookuptool/">https://ami-lookup-tool.fanniemae.com/amilookuptool/</a></td>
        </tr>
        <tr>
          <th>Expiration</th>
          <td>Valid for 60 days from initial approval.</td>
        </tr>
        <tr>
          <th>Ineligible Properties</th>
          <td>Condotels, Non-warrantable condos, Co-Ops, Multi Unit (2-4), Working or hobby farms and Investment Properties.</td>
        </tr>
        <tr>
          <th>Apply</th>
          <td>Applicants apply through The Giving Branch Website.</td>
        </tr>
        <tr>
          <th>Funding</th>
          <td>Lender advances funds, after closing submits final CD to The Giving Branch for confirmation, and then funds are cut to lender.</td>
        </tr>
        <tr>
          <th>Eligible States</th>
          <td>In the following areas Missouri, Illinois, Arkansas, Oklahoma, Iowa, Texas,Kansas, and Tennessee where there are Giving Branch Communities.</td>
        </tr>
      </tbody>
    </table>
        
      </section>
      <section>
        <h2 style="text-align: center; padding:30px;">
                Apply now by clicking the <a href="https://foundation.flatbranchdev.com/down-payment-assistance">link</a>
        </h2>
      </section>
  
    </section>
  </template>
  
  
  <script>
  export default {
    metaInfo: {
    title: "Giving Branch Housing Grant Guidelines | The Giving Branch"
    }
  }</script>
  <style scoped lang="scss">
  .bg {
    background: url("https://cdn.fbhl.com/posts/down-payment-assistance.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 0 200px 0;
    color: white;
  }
  .table-container {
  display: flex;
  justify-content: center;
}

table {
  border-collapse: collapse;
  width: 50%;
  margin-top: 50px;
  border: 2px solid black;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

  </style>
<template>
  <section>
    <div class="bg">
      <div class="container">
        <h1 class="text__center">Inquire About Our Scholarship</h1>
        <p
          class="text__md text__center padding__sm"
        >Please fill out the application and, if you qualify, we will reach out to you within 48 hours with additional information.</p>
      </div>
    </div>
    <section class="container">
      <Scholarship />
    </section>
  </section>
</template>

<script>
import Scholarship from "../../Forms/Scholarship";
export default {
  components: {
    Scholarship
  },
  metaInfo: {
    title: "Scholarship Apply | The Giving Branch"
  }
};
</script>

<style scoped lang="scss">
.bg {
  background: linear-gradient(rgba(71, 119, 61, 0.8), rgba(71, 119, 61, 0.8)),
    url("https://cdn.fbhl.com/posts/what-goes-into-closing-costs-featured-image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0 200px 0;
  * {
    color: $powder;
  }
}
</style>
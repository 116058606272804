import xssFilters from 'xss-filters';
import axios from 'axios';

const removeJS = (content) => {
   const re = /[[\]<>`$%{}()|&";:/=*]/gim;
   return content.replaceAll(re, "");
}

const BASE_URL =
   process.env.NODE_ENV === "development"
      ? "http://localhost:3001/api"
      : "https://api.fbhl.com/api";

const state = {};

const getters = {};

const actions = {
   async startSubmitDonation({ commit }, form) {
      const data = JSON.parse(JSON.stringify(form));
      data.fundraising = data.fundraising === "" ? "none" : data.fundraising;
      data.referredBy = data.referredBy === "" ? "none" : data.referredBy;
      try {
         for (let key in data) {
            data[key] = xssFilters.inHTMLData(data[key]);
            data[key] = removeJS(data[key]);
         }
         await axios.post(`${BASE_URL}/foundation/donation`, data);
         commit("COMPLETE");
         return Promise.resolve("success");
      } catch (err) {
         return Promise.reject(err);
      }
   },
   async startSubmitScholarship({ commit }, form) {
      const data = JSON.parse(JSON.stringify(form));
      try {
         for (let key in data) {
            data[key] = xssFilters.inHTMLData(data[key]);
            data[key] = removeJS(data[key]);
         }
         await axios.post(`${BASE_URL}/foundation/scholarship`, data);
         commit("COMPLETE");
         return Promise.resolve("success");
      } catch (err) {
         return Promise.reject(err);
      }
   },
   async startSubmitCar({ commit }, form) {
      const data = JSON.parse(JSON.stringify(form));
      try {
         for (let key in data) {
            data[key] = xssFilters.inHTMLData(data[key]);
            data[key] = removeJS(data[key]);
         }
         await axios.post(`${BASE_URL}/foundation/car`, data);
         commit("COMPLETE");
         return Promise.resolve("success");
      } catch (err) {
         return Promise.reject(err);
      }
   }
};

const mutations = {
   COMPLETE() {
      return;
   }
}

export default {
   state,
   getters,
   actions,
   mutations
}